import Vue from "vue";

export default {

    recuperarProcessosLancamento(){
        return Vue.axios.get("lancamento/consulta/processos");
    },

    recuperarInscricoesLancamentoPorOpcao(opcaoOid){
        return Vue.axios.get('lancamento/inscricoes/' + opcaoOid);
    },

    lancarResultado(inscricoes){
        return Vue.axios.post('lancamento', inscricoes);
    },

    lancarBonusService(inscricoes){
        return Vue.axios.post('lancamento/bonus', inscricoes);
    },

    recuperarOpcoesPorProcesso(processoOid) {
        return Vue.axios.get("lancamento/processo/" + processoOid);
    },

}
