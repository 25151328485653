<template>
  <v-sheet outlined rounded class="pt-6 px-6">
    <v-sheet width="80" class="ml-1 mt-n9 mb-3">
      <div
          class="subtitle-1 grey--text text--darken-2 font-weight-light text-center"
      >
        Processo
      </div>
    </v-sheet>
    <v-autocomplete
        v-model="tipoProcessoSelecionado"
        :items="tiposProcessosExistentes"
        dense
        item-text="nome"
        item-value="nome"
        label="Selecione um Tipo de Processo"
        outlined
        clearable
        :disabled="disabled"
    >
      <template v-slot:selection="data">
        <v-sheet class="mx-1 my-1 pa-1" color="secondary" rounded
        ><span class="mx-2 d-flex">{{ data.item }}</span>
        </v-sheet>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-html="data.item"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-autocomplete
        v-model="processoSelecionado"
        @input="atualizarProcessoSelecionado"
        :disabled="!tipoProcessoSelecionado || disabled"
        :items="processosPorTipoProcesso"
        dense
        item-text="nome"
        label="Selecione um Processo"
        outlined
        return-object
        clearable
    >
      <template v-slot:selection="data">
        <v-sheet class="mx-1 my-1 pa-1" color="secondary" rounded
        ><span class="mx-2 d-flex">{{ data.item.nome }}</span>
        </v-sheet>
      </template>
      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title v-html="data.item.nome"></v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-sheet>
</template>

<script>
import _ from "lodash";

export default {
  name: "EscolhaProcessoAutocomplete",

  props: {
    value: {type: Object, required: true},
    processos: {type: Array, required: true},
    disabled: Boolean,
  },

  watch: {
    value: {
      handler() {
        if (!_.isEmpty(this.value) && !!this.value.tipoProcesso) {
          this.processoSelecionado = {
            ...this.value,
            tipoProcessoDescricao: this.value.tipoProcesso.descricao
          };
          this.tipoProcessoSelecionado = this.value.tipoProcesso.descricao;
        }
      },
      deep: true
    },

    tipoProcessoSelecionado: {
      handler() {
        this.processoSelecionado = {};
        this.$emit("input", this.processoSelecionado);
      },
      deep: true
    },

    tiposProcessosExistentes: {
      handler() {
        let tiposProcesso = this.tiposProcessosExistentes;

        if(tiposProcesso.length === 1){
          this.tipoProcessoSelecionado = tiposProcesso[0];
        }
      }
    }
  },

  data() {
    return {
      tipoProcessoSelecionado: null,
      processoSelecionado: {},
    };
  },

  computed: {
    tiposProcessosExistentes() {
      return Object.keys(
          _.groupBy(this.processos, "tipoProcessoDescricao")
      ).sort();
    },

    processosPorTipoProcesso() {
      return this.processos.filter(
          p => p.tipoProcessoDescricao === this.tipoProcessoSelecionado
      );
    }
  },

  methods: {
    atualizarProcessoSelecionado() {
      this.$emit("input", this.processoSelecionado || {});
    }
  },
};
</script>
