import Vue from 'vue';

export default{
    recuperarInscricaoLinkVideoTHEPorInscricao(inscricaoOid){
        return Vue.axios.get('inscricaoLinkTHE/' + inscricaoOid);
    },

    recuperarInscricoesLinkVideoTHEPorOpcao(opcaoOid) {
        return Vue.axios.get('inscricaoLinkTHE/opcao/' + opcaoOid)
    }
}